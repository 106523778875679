import Accordion from './Accordion';

export default class Accordions {
  constructor(debug = false) {
    this.debug = debug;
    if (this.debug) console.log('Accordions init');

    if (!this.setVars()) return;

    if (this.debug) console.log('Accordions succesful');
  }

  setVars() {
    this.rootArr = document.querySelectorAll('[data-accordion]');
    if (!this.rootArr.length) return;

    this.rootArr.forEach((item) => {
      new Accordion(item);
    });

    return true;
  }
}
