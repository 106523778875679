import Swiper from 'swiper';
import { Zoom, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

// import 'swiper/css';
import refreshInstances from '../vendors/helpers/refreshInstances';

export class SectionSliderInstance {

  constructor(rootEl, debug = false)
  {
    this.debug = debug;
    if (this.debug) console.log('SectionSliderInstance init');

    if (!this.setVars(rootEl)) return;
    this.initSwiper();
    this.setEvents();

    if (this.debug) console.log('SectionSliderInstance succesful');
  }


  setVars(rootEl)
  {
    this.rootEl = rootEl;
    if (!this.rootEl && this.debug) console.error('SectionSliderInstance failed (1)');
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-section-swiper]');
    if (!this.sliderEl && this.debug) console.error('SectionSliderInstance failed (2)');
    if (!this.sliderEl) return;

    // this.select2El = this.rootEl.querySelector('[data-section-swiper-select2]');

    this.mod = this.rootEl.getAttribute('data-section-swiper-wrapper');

    this.nextButtonEl = this.rootEl.querySelector('[data-section-swiper-next]');
    this.prevButtonEl = this.rootEl.querySelector('[data-section-swiper-prev]');

    this.navEl = this.rootEl.querySelector('[data-section-swiper-nav]');

    return true;
  }


  setEvents()
  {
    // if (this.select2El) {
    //   this.select2El.addEventListener('change', e => this.selectHandler(e));
    // }

    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);

    if (this.nextButtonEl !== null) this.nextButtonEl.addEvent('click.SectionSliderInstance', this.onNextClick);
    if (this.prevButtonEl !== null) this.prevButtonEl.addEvent('click.SectionSliderInstance', this.onPrevClick);
  }


  onNextClick()
  {
    this.swiper.slideNext();
  }


  onPrevClick()
  {
    this.swiper.slidePrev();
  }


  initSwiper() {
    // Swiper.use(Pagination);

    if (this.debug) console.log('SectionSliderInstance swiper init');

    // this.hideHiddenBoxes();

    this.swiper = new Swiper(this.sliderEl, this.getSwiperConfig(this.mod));

    this.swiper.on('init', function () {
        // Your custom code to handle the 'navigationNext' event
        console.log('testhis', this.navigation);
    });

    if (this.debug) console.log('SectionSliderInstance swiper inited');
  }


  selectHandler()
  {
    // this.hideHiddenBoxes();
    this.swiper.update();
  }

  // updatePagination(swiper, current, total) {
  //   if (this.navEl === null) return;

  //   if (total > 1) {
  //     this.navEl.style.display = '';
  //   } else {
  //     this.navEl.style.display = 'none';
  //   };
  // }


  getSwiperConfig(mod) {
    // const pagination = {
    //   el: document.createElement('div'),
    //   type: 'custom',
    //   renderCustom: this.updatePagination.bind(this),
    // }

    const navigation = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      navigationNext: (swiper) => { console.log('## avigationNext1'); },
      on: {
        init: (swiper) => { console.log('## init2'); },
        navigationNext: (swiper) => { console.log('## navigationNext2'); },
      },
    };

    switch (mod) {
      // case 'upsell':
      // case 'related':
      // case 'recent':
      // case 'featured':
      //   return {
      //     slidesPerView: 'auto',
      //     navigation,
      //     loop: false,
      //     zoom: true,
      //     // watchOverflow: true,
      //     on: {
      //       navigationNext: function () { console.log(this); }
      //     },

      //   };

      default:
        return {
          slidesPerView: 'auto',
          navigation,
          loop: false,
          spaceBetween: 24,
          zoom: {
            maxRatio: 2,
            minRation: 1,
          },
          breakpoints: {
            '320': {
              spaceBetween: 12,
            },
            '600': {
              spaceBetween: 24,
            },
          },
          on: {
            navigationNext: (swiper) => { console.log('### navigationNext4') },
            // navigationNext: function () { console.log('### navigationNext3'); },
            navigationShow: (swiper) => { console.log('### navigationShow', swiper); },
            // slideChange: (swiper) =>{ console.log('### slideChange', swiper); },
            // init: (swiper) => console.log('swiper', swiper.params ),
          },

          // pagination,
          // loopedSlides: 5,
        };
    }
  }
}

export default class SectionSlider {
  constructor(debug = false)
  {
    this.debug = debug;
    if (this.debug) console.log('SectionSlider init');
    this.instancesArr = [];

    this.createInstances(debug);
    this.setEvents();

    if (this.debug) console.log('SectionSlider succesful');
  }

  createInstances(debug)
  {
    this.instancesArr = refreshInstances(
      '[data-section-swiper-wrapper]',
      (element) => new SectionSliderInstance(element, debug),
      this.instancesArr
    );
  }

  /**
   * If section is refreshed we need to rebuild the instances because
   *  the data is changed and the event listeners are gone
   */
  setEvents()
  {
    window.addEventListener('projectRefresh', e => this.onRefreshed(e));
    window.addEventListener('sectionRefreshed', e => this.onRefreshed(e));
  }

  onRefreshed()
  {
    this.createInstances(this.debug);
  }
}
