import anime from 'animejs/lib/anime.es';

export default class Accordion {
  constructor(rootEl, debug = false) {
    this.debug = debug;
    if (this.debug) console.log('Accordion init');

    if (!rootEl) return;
    if (!this.setVars(rootEl)) return;

    this.init();
    this.setEvents();

    if (this.debug) console.log('Accordion succesful');
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.ANIMATION_TIME = 800;

    this.tabs          = this.rootEl.querySelectorAll('[data-accordion-tab]');
    this.sections      = this.rootEl.querySelectorAll('[data-accordion-section]');
    this.allowMultiple = this.rootEl.hasAttribute('data-accordion-allow-multiple');
    this.closed        = this.rootEl.hasAttribute('data-accordion-closed');
    this.id            = this.rootEl.getAttribute('id');

    return true;
  }

  init()
  {
    this.sections.forEach((section) => {
      section.style.display = 'flex';
      // this.toggleDisplay(section, false);
      section.setAttribute('data-accordion-section', section.clientHeight);
      section.style.display = 'none';
      section.style.maxHeight = '0px';
      // this.toggleDisplay(section, true);
    });

    // setTimeout(() => {
    //   this.tabs.forEach((tab) => {
    //     this.hideTab(tab);
    //   });

    //   if (this.closed) {
    //     return;
    //   }

      // setTimeout(() => {
      //   this.openTab(this.tabs[0]);
      // }, 100);
    // }, 1);
  }

  setEvents() {
    this.initTabEvents();

    window.addEventListener('accordionRefresh', e => this.onRefreshAction(e));
    window.addEventListener('accordionpublicationsRefresh', e => this.onRefreshAction(e));
    window.addEventListener('accordionevaluationsRefresh', e => this.onRefreshAction(e));
  }

  initTabEvents()
  {
    this.tabs.forEach((tab) => {
      tab.addEventListener('click', () => this.accordionHandler(tab));
    });
  }

  /**
   * Run when paging event fires and changes tab content
   *
   * @param {event} e event
   * @returns void
   */
  onRefreshAction(e) {
    this.debug && console.log('[Accordion.js:63] - e.detail.id', e.detail.id);

    if (this.id !== e.detail.id) return;
    if (!this.setVars(this.rootEl)) return;
    this.init();
    this.initTabEvents();
  }

  accordionHandler(tab) {
    if (this.allowMultiple) {
      this.toggleTab(tab);
    } else {
      this.tabs.forEach((item) => {
        if (item.getAttribute('aria-controls') != tab.getAttribute('aria-controls')) {
          this.hideTab(item);
        }
        // eslint-disable-next-line no-console
        // console.log('[Accordion.js:89] - item #######', item, tab);
      });

      this.openTab(tab);
    }
  }

  toggleTab(tab) {
    if (tab.getAttribute('aria-expanded') === 'false') {
      this.openTab(tab);
    } else {
      this.hideTab(tab);
    }
  }

  openTab(tab) {
    console.log('[Accordion.js:118] - openTab');
    tab.setAttribute('aria-expanded', 'true');
    const controlsId = tab.getAttribute('aria-controls');
    const content = Array.from(this.sections).find(item => item.id === controlsId);
    const sectionHeight = content.getAttribute('data-accordion-section');

    content.style.display = 'flex';
    console.log('[Accordion.js:118] - openTab', content, sectionHeight);

    anime({
      targets: content,
      opacity: 1,
      maxHeight: sectionHeight,
      // visibility: 'visible',
      duration: this.ANIMATION_TIME, // * sectionHeight / 200,
      easing: 'easeInOutQuad',
    }).finished.then(() => {
      console.log('[Accordion.js:116] - openTab done');
      this.toggleDisplay(content, false);
    });
  }

  hideTab(tab) {
    // eslint-disable-next-line no-console
    // console.log('[Accordion.js:118] - hideTab');
    tab.setAttribute('aria-expanded', 'false');
    const controlsId = tab.getAttribute('aria-controls');
    const content    = Array.from(this.sections).find(item => item.id === controlsId);

    anime({
      targets: content,
      opacity: 0,
      maxHeight: 0,
      // visibility: 'hidden',
      duration: this.ANIMATION_TIME / 2,
      easing: 'easeInQuad',
    }).finished.then(() => {
      console.log('[Accordion.js:116] - closeTab done');
      this.toggleDisplay(content, true)
    });
  }

  /**
   * If the hide parameter is true, set the display property to none, otherwise set it to block
   * @param item - The item to be hidden or shown.
   * @param [hide=true] - boolean
   */
  toggleDisplay(item, hide = true) {
    // eslint-disable-next-line no-console
    console.log('[Accordion.js:138] - toggleDisplay');
    item.style.display = hide ? 'none' : 'flex';
  }
}
