import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import 'swiper/css';
import refreshInstances from '../vendors/helpers/refreshInstances';

export class FeaturedProductsInstance {

  constructor(rootEl, debug = false)
  {
    this.debug = debug;
    if (this.debug) console.log('FeaturedProductsInstance init');

    if (!this.setVars(rootEl)) return;
    this.initSwiper();
    this.setEvents();

    if (this.debug) console.log('FeaturedProductsInstance succesful');
  }


  setVars(rootEl)
  {
    this.rootEl = rootEl;
    if (!this.rootEl && this.debug) console.error('FeaturedProductsInstance failed (1)');
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-swiper]');
    if (!this.sliderEl && this.debug) console.error('FeaturedProductsInstance failed (2)');
    if (!this.sliderEl) return;

    this.select2El = this.rootEl.querySelector('[data-swiper-select2]');

    // this.filteredBoxes = this.rootEl.querySelectorAll('[data-swiper-select2-slug]');

    this.mod = this.rootEl.getAttribute('data-swiper-wrapper');

    this.nextButtonEl = this.rootEl.querySelector('[data-swiper-next]');
    this.prevButtonEl = this.rootEl.querySelector('[data-swiper-prev]');
    this.navEl = this.rootEl.querySelector('[data-swiper-nav]');

    return true;
  }


  setEvents()
  {
    if (this.select2El) {
      this.select2El.addEventListener('change', e => this.selectHandler(e));
    }

    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);

    if (this.nextButtonEl !== null) this.nextButtonEl.addEvent('click.FeaturedProductsInstance', this.onNextClick);
    if (this.prevButtonEl !== null) this.prevButtonEl.addEvent('click.FeaturedProductsInstance', this.onPrevClick);
  }


  onNextClick()
  {
    this.swiper.slideNext();
  }


  onPrevClick()
  {
    this.swiper.slidePrev();
  }


  initSwiper() {
    // Swiper.use(Pagination);

    if (this.debug) console.log('FeaturedProductsInstance swiper init');

    // this.hideHiddenBoxes();

    this.swiper = new Swiper(this.sliderEl, this.getSwiperConfig(this.mod));

    if (this.debug) console.log('FeaturedProductsInstance swiper inited');
  }


  // hideHiddenBoxes()
  // {
  //   if (this.filteredBoxes.length > 0
  //     && this.select2El
  //     && this.select2El.value)
  //   {
  //     this.filteredBoxes.forEach((el) => {
  //       if (el.getAttribute('data-swiper-select2-slug') === this.select2El.value) {
  //         el.classList.remove('hidden');
  //       } else {
  //         el.classList.add('hidden');
  //       }
  //     });
  //   }
  // }


  selectHandler()
  {
    // this.hideHiddenBoxes();
    this.swiper.update();
  }


  // updatePagination(swiper, current, total) {
  //   if (this.navEl === null) return;

  //   if (total > 1) {
  //     this.navEl.style.display = '';
  //   } else {
  //     this.navEl.style.display = 'none';
  //   };
  // }


  getSwiperConfig(mod) {
    // const pagination = {
    //   el: document.createElement('div'),
    //   type: 'custom',
    //   renderCustom: this.updatePagination.bind(this),
    // }

    const navigation = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    };

    switch (mod) {
      case 'offer':
        return {
          slidesPerView: 'auto',
          // pagination,
          loopedSlides: 5,
          loop: true,
        };

      case 'people':
        return {
          slidesPerView: 'auto',
          // pagination,
          loop: false,
        };

      case 'upsell':
      case 'related':
      case 'recent':
      case 'featured':
        return {
          slidesPerView: 'auto',
          navigation,
          loop: false,
          // watchOverflow: true,
        };

      default:
        return {
          slidesPerView: 'auto',
          pagination,
          navigation,
          // loopedSlides: 5,
          loop: false,
        };
    }
  }
}

export default class FeaturedProducts {
  constructor(debug = false)
  {
    this.debug = debug;
    if (this.debug) console.log('FeaturedProducts init');
    this.instancesArr = [];

    this.createInstances(debug);
    this.setEvents();

    if (this.debug) console.log('FeaturedProducts succesful');
  }

  createInstances(debug)
  {
    this.instancesArr = refreshInstances(
      '[data-swiper-wrapper]',
      (element) => new FeaturedProductsInstance(element, debug),
      this.instancesArr
    );
  }

  /**
   * If section is refreshed we need to rebuild the instances because
   *  the data is changed and the event listeners are gone
   */
  setEvents()
  {
    window.addEventListener('projectRefresh', e => this.onRefreshed(e));
    window.addEventListener('sectionRefreshed', e => this.onRefreshed(e));
  }

  onRefreshed()
  {
    this.createInstances(this.debug);
  }
}
