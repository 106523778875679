/**
 * refreshInstances
 * @param {string} selector css selector
 * @param {function} creatorFn instance create function
 * @param {array} prevInstances previous instances array
 * @returns {array} refreshed instances array
 */
export default function refreshInstances(selector, creatorFn, prevInstances = [])
{
  const currElements = Array.from(document.querySelectorAll(selector));
  const prevElements = prevInstances.map(obj => obj.element);

  // prevent reinit existing instances
  const newElements = currElements.filter(element => !prevElements.includes(element));

  // clean instances from removed elements
  const nextInstances = prevInstances.filter(obj => currElements.includes(obj.element));

  newElements.forEach((element) => {
    nextInstances.push({
      element,
      instance: creatorFn(element),
    });
  });

  return nextInstances;
}
