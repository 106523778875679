// import Modal from './classes/Modal';

import Accordions from './classes/Accordions';
import ContactForm from './classes/ContactForm';
import Header from './classes/Header';
import MobileMenu from './classes/MobileMenu';
import ReusableCopy from './classes/ReusableCopy';
import HeaderBanner from './classes/HeaderBanner';
import FeaturedSlider from './classes/FeaturedSlider';
import SectionSlider from './classes/SectionSlider';
import TextMore from './classes/TextMore';
import MenuSearch from './classes/MenuSearch';
import CookieBotActions from './classes/CookieBotActions';

class Core {
  // constructor() {
  //   console.log('running core..');
  // }

  static run(stage) {
    // console.log(`[initJS.js] (${stage} scripts)`);

    switch (stage) {
      case 'dom':
        // new Modal();
        new Accordions();
        new Header();
        new MobileMenu();
        new HeaderBanner();
        new FeaturedSlider();
        new SectionSlider();
        new TextMore();
        new MenuSearch();
        new CookieBotActions();

        break;

      case 'vue':
        // new Lightbox();
        break;

      case 'page':
        new ReusableCopy();
        new ContactForm();
        break;

      default:
    }
  }
}

const readyDOM = (fn) => {
  if (document.attachEvent
    ? document.readyState === 'complete'
    : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

const readyPage = (fn) => {
  window.addEventListener('load', fn);
};

const readyVue = (fn) => {
  window.addEventListener('vueMounted', fn);
};

readyDOM(() => {
  console.log('[initJS.js] readyDOM');
  Core.run('dom');
});

readyPage(() => {
  console.log('[initJS.js] readyPage');
  Core.run('page');
});

let componentsMounted = 0;
readyVue((e) => {
  componentsMounted++;
  console.log(`[initJS.js] (vue/${componentsMounted}of${e.detail.componentCount}) ready`);
  if (!parseInt(e.detail.componentCount)) {
    console.log('[initJS.js] readyVue');
    Core.run('vue');
  } else if (parseInt(e.detail.componentCount) === componentsMounted) {
    console.log(`[initJS.js] readyVue (${componentsMounted})`);
    Core.run('vue');
  }
});


// window.addEventListener('vueMounted', (e) => {
//   componentMounted++;
//   // console.log('[initJS.js] vue mounted event - componentCount:',
//   //   e.detail.componentCount, componentMounted);
//   parseInt(e.detail.componentCount) === componentMounted && Core.run('vue');
// });
