export default class CookieBotActions {

  constructor(debug = false) {

    this.debug = debug;

    if (this.debug) console.log('CookieBotActions init');

    if (this.setVars()) {
      if (this.debug) console.log('CookieBotActions vars init done');
      this.setEvents();
    }
  }

  setVars() {
    return true;
  }

  setEvents() {
    window.addEventListener('CookiebotOnAccept', (e) => {
      this.onUserdecision('accept');
    });

    window.addEventListener('CookiebotOnDecline', (e) => {
      this.onUserdecision('decline');
    });
  }


  onUserdecision(action) {
    const currentConsentId = Cookiebot?.consentID || -1;
    const storedConsentId = sessionStorage.getItem('cookiebot_consent_id') || -2;

    if (currentConsentId !== storedConsentId && currentConsentId !== -1) {
      sessionStorage.setItem('cookiebot_consent_id', currentConsentId);
      window.location.reload();
    }
  }
}
