export const KEYS = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  SPACE: 32,
  ENTER: 13,
  ESC: 27,
  TAB: 9,
};

export default {
  KEYS,
};
