import anime from 'animejs/lib/anime.es';
import { customFocus } from './HasFocus';
import { KEYS } from '../vendors/helpers/consts';

const DURATION = 200;

/**
 * How To
 *  - wrapper: data-text-more
 *  - button (with target): data-text-more-button="#respond"
 *      target can be non unique within wrapper or global unique
 *
 *
 */
export class TextMoreInstance {

  constructor(rootEl, debug = false)
  {
    this.debug = debug;
    if (this.debug) console.log('TextMoreInstance init');

    if (!this.setVars(rootEl)) return;
    this.bindThis();
    this.refresh();
    this.bindEvents();

    if (this.debug) console.log('TextMoreInstance succesful');
  }

  setVars(rootEl)
  {
    this.rootEl = rootEl;
    if (!this.rootEl) return false;

    return true;
  }

  bindThis()
  {
    this.onButtonClickEvent = this.onButtonClick.bind(this);
    this.onButtonKeydownEvent = this.onButtonKeydown.bind(this);
    this.onRefreshEvent = this.onRefresh.bind(this);
  }

  refresh()
  {
    const buttonsArr = this.rootEl.querySelectorAll('[data-text-more-button]');
    this.itemObjsArr = this.getItemObjsArr(buttonsArr);

    this.bindItemsEvents();
  }

  bindItemsEvents()
  {
    this.itemObjsArr.forEach((itemObj) => {
      itemObj.buttonEl.addEvent('click.TextMore', this.onButtonClickEvent);
      itemObj.buttonEl.addEvent('keydown.TextMore', this.onButtonKeydownEvent);
    });
  }

  bindEvents()
  {
    this.rootEl.addEvent('refreshTextMore.TextMore', this.onRefreshEvent);
  }

  onRefresh()
  {
    this.refresh();
  }

  getItemObjsArr(buttonsArr)
  {
    return Array.prototype.map.call(buttonsArr, (buttonEl, index) => {
      const id = buttonEl.getAttribute('data-text-more-button');
      let contentEl = this.rootEl.querySelector(`[data-text-more-content="${id}"]`);

      if (!contentEl) {
        contentEl = document.querySelector(`${id}`);
      }

      if (!contentEl) return;

      return {
        id,
        index,
        buttonEl,
        contentEl,
        buttonActiveClass: buttonEl.getAttribute('data-text-more-class-active') || 'active',
        contentActiveClass: contentEl.getAttribute('data-text-more-class-active') || 'active',
      };
    });
  }

  static skipForButtonInside(e)
  {
    const closestButton = e.target.closest('a, button, [data-text-more-button]');

    return (closestButton !== e.currentTarget);
  }

  onButtonClick(e)
  {
    if (TextMoreInstance.skipForButtonInside(e)) return;

    e.preventDefault();
    const itemId = e.currentTarget.getAttribute('data-text-more-button');
    this.toggleItem(itemId);
  }

  onButtonKeydown(e)
  {
    if (TextMoreInstance.skipForButtonInside(e)) return;

    switch (e.keyCode) {

      case KEYS.SPACE:
      case KEYS.ENTER: {
        e.preventDefault();
        const itemId = e.target.getAttribute('data-text-more-button');
        this.toggleItem(itemId);
        break;
      }

      case KEYS.DOWN:
      case KEYS.RIGHT: {
        const nextItemObj = this.getItemObjByButtonOffset(e.target, 1);
        this.focusItemButtonEl(nextItemObj, e);
        break;
      }

      case KEYS.UP:
      case KEYS.LEFT: {
        const prevItemObj = this.getItemObjByButtonOffset(e.target, -1);
        this.focusItemButtonEl(prevItemObj, e);
        break;
      }

      default: break;
    }
  }

  getItemObjByButtonOffset(buttonEl, indexOffset)
  {
    const itemId = buttonEl.getAttribute('data-text-more-button');
    const currentItemObj = this.getItemObjById(itemId);
    if (currentItemObj === null) return null;

    return this.itemObjsArr[currentItemObj.index + indexOffset] || null;
  }

  focusItemButtonEl(itemObj, e)
  {
    if (itemObj === null) return;

    e.preventDefault();
    this.focusEl(itemObj.buttonEl);
  }

  focusEl(el)
  {
    // el.focus();
    customFocus(el);
  }

  getItemObjById(itemId)
  {
    return this.itemObjsArr.find(itemObj => itemObj.id === itemId);
  }

  toggleItem(itemId)
  {
    const itemObj = this.getItemObjById(itemId);
    if (itemObj === null) return;

    const { contentEl, contentActiveClass } = itemObj;
    if (contentEl.classList.contains(contentActiveClass)) {
      this.collapseItem(itemObj);
    } else {
      this.expandItem(itemObj);
    }
  }

  expandItem(itemObj)
  {
    const {
      contentEl,
      buttonEl,
      buttonActiveClass,
      contentActiveClass,
    } = itemObj;

    if (contentEl.classList.contains(contentActiveClass)) return;

    anime.remove(contentEl);
    anime.set(contentEl, {
      height: contentEl.offsetHeight,
    });

    contentEl.classList.add(contentActiveClass);
    buttonEl.classList.add(buttonActiveClass);
    buttonEl.setAttribute('aria-expanded', true);
    buttonEl.setAttribute('aria-selected', true);

    anime({
      targets: contentEl,
      height: contentEl.scrollHeight,
      opacity: 1,
      duration: DURATION,
      easing: 'easeOutCubic',
      complete: () => {
        contentEl.style.height = '';
      },
    });
  }

  collapseItem(itemObj)
  {
    const {
      contentEl,
      buttonEl,
      buttonActiveClass,
      contentActiveClass,
    } = itemObj;

    if (!contentEl.classList.contains(contentActiveClass)) return;

    anime.remove(contentEl);
    anime.set(contentEl, {
      height: contentEl.offsetHeight,
    });

    buttonEl.classList.remove(buttonActiveClass);
    buttonEl.setAttribute('aria-expanded', false);
    buttonEl.setAttribute('aria-selected', false);

    anime({
      targets: contentEl,
      height: 0,
      opacity: 0,
      duration: DURATION,
      easing: 'easeOutCubic',
      complete: () => {
        contentEl.classList.remove(contentActiveClass);
      },
    });
  }
}

export default class TextMore {
  constructor(debug = false)
  {
    if (debug) console.log('TextMore init');

    if (this.createInstances(debug)) return;

    if (debug) console.log('TextMore succesful');
  }

  createInstances(debug)
  {
    this.instancesArr = Array.prototype.map.call(
      document.querySelectorAll('[data-text-more]'),
      element => new TextMoreInstance(element, debug),
    );
  }
}
