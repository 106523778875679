import axios from 'axios';

const STATUS = {
  OK: 200,
};

class ContactFormInstance {
  constructor(rootEl, debug = false) {
    this.debug = debug;
    this.debug && console.log('ContactFormInstance init');

    if (!rootEl) return;
    if (!this.setVars(rootEl)) return;

    this.setEvents();
    this.refreshElementsVisibility();

    this.debug && console.log('ContactFormInstance succesful');
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.resultsEl        = this.rootEl.querySelector('[data-contact-info]');
    this.resultsInfoEl    = this.rootEl.querySelector('[data-contact-info-info]');
    this.resultsResultEl  = this.rootEl.querySelector('[data-contact-info-result]');
    this.resultsWaitingEl = this.rootEl.querySelector('[data-contact-info-waiting]');
    this.resultsErrorEl   = this.rootEl.querySelector('[data-contact-info-error]');
    this.resultsButtonEl  = this.rootEl.querySelector('[data-contact-info-button]');
    if (!this.resultsEl || !this.resultsEl || !this.resultsInfoEl || !this.resultsResultEl
      || !this.resultsErrorEl || !this.resultsButtonEl) return;

    this.formEl = this.rootEl.querySelector('[data-contact-form]');
    if (!this.formEl) return;

    this.locale = this.rootEl.getAttribute('data-contact-locale');
    if (!this.locale) return;
    this.locale = JSON.parse(this.locale);

    this.showFormEl  = this.rootEl.querySelectorAll('[data-contact-form-show]');
    this.closeFormEl = this.rootEl.querySelectorAll('[data-contact-form-close]');

    this.prefix = 'contactForm';

    this.select = this.rootEl.querySelector('[data-contact-select]');
    if (!this.select) return;

    this.mailToId    = this.formEl.querySelector('[name="your-addressee-id"]');
    this.mailToTitle = this.formEl.querySelector('[name="your-addressee"]');
    this.mailToEmail = this.formEl.querySelector('[name="your-addressee-email"]');

    this.selectId = this.select.getAttribute('id');
    if (!this.selectId) return;

    this.apiUrlSearch = this.rootEl.getAttribute('data-contact-url-search');
    this.lang = this.rootEl.getAttribute('data-contact-lang');
    if (!this.apiUrlSearch || !this.lang) return;

    this.classes = {
      resultsWaiting: this.resultsEl.getAttribute('data-contact-info-waiting'),
      resultsEmpty: this.resultsEl.getAttribute('data-contact-info-empty'),
      active: 'active',
    };

    this.state = {
      term: this.select.value,
      page: 1,
      perPage: 10,

      waiting: false,
      empty: false,
      info: true,
      result: false,
      error: false,
      button: false,
    };

    return true;
  }


  setState(state)
  {
    this.state = {
      ...this.state,
      ...state,
    };

    this.debug && console.log('new state, query results?', this.state);

    this.refreshElementsVisibility();

    // this.queryResults();
  }


  setEvents()
  {
    this.showFormEl.forEach((item) => {
      item.addEvent('click.ContactForm', (e) => {
        e.preventDefault();
        this.showForm();
      });
    });

    this.closeFormEl.forEach((item) => {
      item.addEvent('click.ContactForm', (e) => {
        e.preventDefault();
        this.hideForm();
      });
    });

    window.addEventListener('customSelectOnChange', this.onSelectChange.bind(this));
  }


  onSelectChange(e)
  {
    // we can have many instances so check if this is the one
    if (e.detail.id === this.selectId)
    {
      this.setState({
        term: e.detail.value,
      });

      this.queryResults();
    }
    else {
      this.debug && console.log('[ContactForm.js:87] - this is not us - skip!',
        e.detail.id, this.selectId);
    }
  }


  showForm() {
    this.resultsEl.classList.add(`${this.prefix}__infoDescription--hidden`);
    this.formEl.classList.add(`${this.prefix}__infoForm--active`);
  }


  hideForm() {
    this.resultsEl.classList.remove(`${this.prefix}__infoDescription--hidden`);
    this.formEl.classList.remove(`${this.prefix}__infoForm--active`);
  }


  queryResults()
  {
    ContactFormInstance.setContent(this.resultsResultEl, '');

    this.setWaiting(true);
    this.sendSearchRequest(this.state).then((results) => {

      this.debug && console.log('[ContactForm.js:92] - results', results.text);

      this.setResults(results);
      this.setFormData(results);
      this.setWaiting(false);
    });
  }

  sendSearchRequest(state)
  {
    return new Promise((resolve) => {
      axios
        .get(this.apiUrlSearch, {
          params: {
            id: state.term,
            lang: this.lang,
          },
        })
        .then(({ status, data }) => {
          this.debug && console.log('response', status, data);
          if (status !== STATUS.OK) throw new Error('Response error');

          return data;
        })
        .then(({
          id, email, title, text,
        }) => {
          this.debug && console.log('data', text);
          resolve({
            id,
            email,
            title,
            text, // text: text
          });
        })
        .catch((data) => {
          this.debug && console.log('catch', data);
          resolve({ text: '' });
        });
    });
  }


  setWaiting(status = true)
  {
    this.setState({
      waiting: status,
      empty: status === false && this.state.result,
      error: status === false && !this.state.result,
      button: status === false && this.state.result === true,
      info: status !== true && !this.state.result,
      result: true,
    });

    // console.log('[ContactForm.js:122] - setWaiting', status, this.state);

    // ContactFormInstance.setClass(this.resultsEl, this.classes.resultsWaiting, status);
  }


  setResults({ text = '' }) {
    if (text.length > 0) {
      ContactFormInstance.setContent(this.resultsResultEl, text);
    }
    else {
      ContactFormInstance.setContent(this.resultsErrorEl, this.locale.noResults);
    }

    this.setState({
      empty: text.length === 0,
      button: text.length > 0,
      result: text.length > 0,
      info: text.length === 0,
    });
  }


  setFormData({ id = '', email = '', title = '' }) {
    // eslint-disable-next-line no-console
    this.debug && console.log('[ContactForm.js:229] - text', id, email, title);

    this.mailToId.value = id;
    this.mailToTitle.value = title;
    this.mailToEmail.value = email;
  }


  refreshElementsVisibility()
  {
    this.debug && console.log('[ContactForm.js:202] - refreshElementsVisibility', this.state);

    // waiting: false,
    // empty: false,
    // info: false,
    // result: false,
    // error: false,
    // button: false,

    ContactFormInstance.setClass(this.resultsInfoEl, this.classes.active, this.state.info);
    ContactFormInstance.setClass(this.resultsResultEl, this.classes.active, this.state.result);
    ContactFormInstance.setClass(this.resultsErrorEl, this.classes.active, this.state.error);
    ContactFormInstance.setClass(this.resultsButtonEl, this.classes.active, this.state.button);

    ContactFormInstance.setClass(this.resultsWaitingEl, this.classes.active, this.state.waiting);
  }


  static setContent(el, content, html = true)
  {
    if (html) {
      el.innerHTML = content;
    } else {
      el.innerText = content;
    }
  }


  static setClass(el, className, status = true)
  {
    if (status) {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  }
}


export default class ContactForm {
  constructor(debug = false) {
    this.debug = debug;
    this.debug && console.log('ContactForm init');

    if (!this.setVars()) return;

    this.debug && console.log('ContactForm succesful');
  }

  setVars() {
    this.rootArr = document.querySelectorAll('[data-contact-wrapper]');
    if (!this.rootArr.length) return;

    this.rootArr.forEach((item) => {
      new ContactFormInstance(item, this.debug);
    });

    return true;
  }
}
