export default class MenuSearch {

  constructor(debug = false)
  {
    this.debug = debug;
    this.debug && console.log('MenuSearch init');

    if (this.setVars()) {
      this.debug && console.log('MenuSearch vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    // this.MenuSearchArr = [...document.querySelectorAll('.jsMenuSearch')];
    this.searchButton = document.querySelector('[data-menu-search]');
    this.searchForm = document.querySelector('.widget_product_search');
    if (!this.searchButton || !this.searchForm) return false;

    return true;
  }

  setEvents()
  {
    this.searchButton.addEvent('click', (e) => {
      e.preventDefault();
      this.onClick(e);
    });
  }

  onClick(e)
  {
    this.debug && console.log('show menu search');

    // this.searchForm.style.display = 'block';
    this.searchForm.classList.toggle("visible");
  }
}
