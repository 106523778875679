export default class HeaderBanner {
  constructor(debug = false) {
    this.debug = debug;
    this.debug && console.log('HeaderBanner init');

    if (!this.setVars()) return;

    this.setEvents();

    this.debug && console.log('HeaderBanner succesful');
  }

  setVars(rootEl) {
    this.headerBannerEl      = document.querySelector('[data-header-banner]');
    this.headerBannerCloseEl = document.querySelector('[data-header-banner-close]');

    if (!this.headerBannerEl || !this.headerBannerCloseEl) return;

    this.classes = {
      // resultsWaiting: this.resultsEl.getAttribute('data-contact-info-waiting'),
      // resultsEmpty: this.resultsEl.getAttribute('data-contact-info-empty'),
      hidden: 'hidden',
    };

    return true;
  }

  setEvents()
  {
    this.headerBannerCloseEl.addEvent('click.HeaderBanner', (e) => {
      e.preventDefault();
      this.onHideBanner(e);
    });
  }


  onHideBanner(e)
  {
    this.headerBannerEl.addClass('hidden');
    Cookies.set('cftn_header_banner', 'closed', { expires: 7 })
  }
}
