import { KEYS } from '../vendors/helpers/consts';

export function customFocus(el, preventScroll = false) {
  window.dispatchEvent(new CustomEvent('customFocus', {
    detail: { el, preventScroll },
  }));
}

export default class HasFocus
{
  constructor(debug = false)
  {
    this.debug = debug;
    if (this.debug) console.log('HasFocus init');

    if (!this.setVars()) return;
    this.setEvents();

    if (this.debug) console.log('HasFocus succesful');
  }

  setVars()
  {
    this.lastFocusedEl = null;
    this.FOCUS_CLASS = 'hasFocus';

    return true;
  }

  setEvents()
  {
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onFocusout = this.onFocusout.bind(this);
    this.onCustomFocus = this.onCustomFocus.bind(this);

    window.addEventListener('keyup', this.onKeyUp);
    window.addEventListener('focusout', this.onFocusout);
    window.addEventListener('customFocus', this.onCustomFocus);
  }

  onKeyUp(e)
  {
    const { target, keyCode } = e;

    if (keyCode !== KEYS.TAB) return;

    if (this.lastFocusedEl !== null) this.lastFocusedEl.removeClass(this.FOCUS_CLASS);

    const nodeName = target.nodeName.toUpperCase();
    if (
      nodeName === 'A'
      || nodeName === 'BUTTON'
      || nodeName === 'INPUT'
      || nodeName === 'SELECT'
      || nodeName === 'TEXTAREA'
      // || (nodeName === 'LABEL' && parseInt(target.getAttribute('tabindex')) >= 0)
      || (target.hasAttribute('tabindex') && parseInt(target.getAttribute('tabindex')) >= 0)
    ) {
      this.focusEl(target);
    }
  }

  onFocusout(e)
  {
    this.unfocusEl(e.target);
  }

  focusEl(el)
  {
    el.addClass(this.FOCUS_CLASS);
    this.lastFocusedEl = el;
  }

  unfocusEl(el)
  {
    if (el.hasClass(this.FOCUS_CLASS)) {
      el.removeClass(this.FOCUS_CLASS);
      this.lastFocusedEl = null;
    }
  }

  onCustomFocus(e)
  {
    const { el, preventScroll = false } = e.detail;

    // el.focus();
    el.focus({
      preventScroll,
    });
    this.focusEl(el);
  }
}
