// import anime from 'animejs/lib/anime.es';

export default class MobileMenu {

  constructor(debug = false) {

    this.debug = debug;

    if (this.debug) console.log('MobileMenu init');

    if (this.setVars()) {
      if (this.debug) console.log('MobileMenu vars init done');
      this.setEvents();
    }
  }


  setVars()
  {
    this.mobileMenuArr = document.querySelectorAll('[data-hamburger]');
    if (!this.mobileMenuArr.length) return false;

    return true;
  }


  setEvents()
  {
    this.mobileMenuArr.forEach((el) => {
      el.addEvent('click.MobileMenu', (e) => {
        e.preventDefault();
        this.onClick(e.target);
      });
    });
  }

  onClick() {
    if (this.debug) console.log('[MobileMenu:55]', 'mobile-menu');

    const eventStart = new CustomEvent('ModalShouldShow', {
      detail: { type: 'mobile-menu' },
    });
    window.dispatchEvent(eventStart);
  }
}
